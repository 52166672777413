<script>
import {
  defineComponent, onBeforeMount,
  ref, watch,
} from "vue";
import {useMeta} from "vue-meta";
import Editor from "primevue/editor";
import {useI18n} from "vue-i18n";
import Skeleton from "primevue/skeleton"
import Button from "primevue/button";
import { useApiCall } from "@/composables/useApiCall";
import {
  privacyPolicyApiCall,
  termsOfUseApiCall,
  personalDataRulesApiCall,
  updatePrivacyPolicyApiCall,
  updateTermsOfUseApiCall,
  updatePersonalDataRulesApiCall,
} from "@/services/policy"
import MainCard from "@/components/cards/MainCard.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
export default defineComponent({
  layout: { name: 'AdminLayout' },
  components: {
    ButtonSuccess,
    MainCard,
    Editor,
    Button,
    Skeleton,
  },
  setup() {
    const {t} = useI18n()
    useMeta({
      title: t('menu.blocks'),
    });
    const privacyPolicyFields = ref('')
    const termsOfUseFields = ref('')
    const personalDataRulesFields = ref('')
    const isUpdatedPrivacyPolicy = ref(false)
    const isUpdatedTermsOfUse = ref(false)
    const isUpdatedPersonalDataRules = ref(false)

    const {
      isLoading: isPrivacyPolicyLoading,
      data: privacyPolicyData,
      error: privacyPolicyError,
      executeApiCall: getPrivacyPolicyAction,
    } = useApiCall(privacyPolicyApiCall, true)
    const {
      isLoading: isTermsOfUseLoading,
      data: termsOfUseData,
      error: termsOfUseError,
      executeApiCall: getTermsOfUseAction,
    } = useApiCall(termsOfUseApiCall, true)
    const {
      isLoading: isPersonalDataRulesLoading,
      data: personalDataRulesData,
      error: personalDataRulesError,
      executeApiCall: personalDataRulesAction,
    } = useApiCall(personalDataRulesApiCall, true)

    const {
      isLoading: isUpdatePrivacyPolicyLoading,
      data: updatePrivacyPolicyData,
      error: updatePrivacyPolicyError,
      executeApiCall: updatePrivacyPolicyAction,
    } = useApiCall(updatePrivacyPolicyApiCall, true)
    const {
      isLoading: isUpdateTermsOfUseLoading,
      data: updateTermsOfUseData,
      error: updateTermsOfUseError,
      executeApiCall: updateTermsOfUseAction,
    } = useApiCall(updateTermsOfUseApiCall, true)
    const {
      isLoading: isUpdatePersonalDataRulesLoading,
      data: updatePersonalDataRulesData,
      error: updatePersonalDataRulesError,
      executeApiCall: updatePersonalDataRulesAction,
    } = useApiCall(updatePersonalDataRulesApiCall, true)

    const updatePrivacyPolicy = async () => {
      try {
        await updatePrivacyPolicyAction({text: privacyPolicyFields.value})
        isUpdatedPrivacyPolicy.value = true
      } catch (e) {
        console.error(e)
      }
    }

    const updateTermsOfUse = async () => {
      try {
        await updateTermsOfUseAction({text: termsOfUseFields.value})
        isUpdatedTermsOfUse.value = true
      } catch (e) {
        console.error(e)
      }
    }

    const updatePersonalDataRules = async () => {
      try {
        await updatePersonalDataRulesAction({text: personalDataRulesFields.value})
        isUpdatedPersonalDataRules.value = true
      } catch (e) {
        console.error(e)
      }
    }

    watch(() => privacyPolicyFields.value,
        () => {
          isUpdatedPrivacyPolicy.value = false
        })

    watch(() => termsOfUseFields.value,
        () => {
          isUpdatedTermsOfUse.value = false
        })

    watch(() => personalDataRulesFields.value,
        () => {
          isUpdatedPersonalDataRules.value = false
        })

    onBeforeMount(async () => {
      try {
        await getPrivacyPolicyAction()
        if (privacyPolicyData.value) {
          privacyPolicyFields.value = privacyPolicyData.value.data.text
        }
      } catch (e) {
        console.error(e)
      }
      try {
        await getTermsOfUseAction()
        if (termsOfUseData.value) {
          termsOfUseFields.value = termsOfUseData.value.data.text
        }
      } catch (e) {
        console.error(e)
      }
      try {
        await personalDataRulesAction()
        if (personalDataRulesData.value) {
          personalDataRulesFields.value = personalDataRulesData.value.data.text
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      privacyPolicyFields,
      termsOfUseFields,
      personalDataRulesFields,
      isUpdatedPrivacyPolicy,
      isUpdatedTermsOfUse,
      isUpdatedPersonalDataRules,
      updatePrivacyPolicy,
      updateTermsOfUse,
      updatePersonalDataRules,
      isPrivacyPolicyLoading,
      isTermsOfUseLoading,
      isPersonalDataRulesLoading,
      isUpdatePrivacyPolicyLoading,
      isUpdateTermsOfUseLoading,
      isUpdatePersonalDataRulesLoading,
      updatePrivacyPolicyError,
      updateTermsOfUseError,
      updatePersonalDataRulesError,
    };
  },
})
</script>

<template>
  <div class="relative">
    <section v-if="!isPrivacyPolicyLoading">
      <div class="flex justify-content-between align-items-center mb-4">
        <h2>{{ t('policy.privacy') }}</h2>
        <ButtonSuccess v-if="isUpdatedPrivacyPolicy" :label="t('button.changesSaved')" class="btn-fix-height"/>
        <Button v-else @click="updatePrivacyPolicy" :loading="isUpdatePrivacyPolicyLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
      </div>
      <MainCard :title="t('websiteCard.infrastructure.input')">
        <Editor v-model="privacyPolicyFields"></Editor>
        <span v-if="updatePrivacyPolicyError?.data.text" class="flex text-xs color-error">
        {{ updatePrivacyPolicyError?.data.text[0] }}
      </span>
      </MainCard>
    </section>
    <Skeleton v-else height="256px"></Skeleton>

    <section v-if="!isTermsOfUseLoading" class="mt-6">
      <div class="flex justify-content-between align-items-center mb-4">
        <h2>{{ t('policy.user') }}</h2>
        <ButtonSuccess v-if="isUpdatedTermsOfUse" :label="t('button.changesSaved')" class="btn-fix-height"/>
        <Button v-else @click="updateTermsOfUse" :loading="isUpdateTermsOfUseLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
      </div>
      <MainCard :title="t('websiteCard.infrastructure.input')">
        <Editor v-model="termsOfUseFields"></Editor>
        <span v-if="updateTermsOfUseError?.data.text" class="flex text-xs color-error">
        {{ updateTermsOfUseError?.data.text[0] }}
      </span>
      </MainCard>
    </section>
    <Skeleton v-else height="256px"></Skeleton>

    <section v-if="!isPersonalDataRulesLoading" class="mt-6">
      <div class="flex justify-content-between align-items-center mb-4">
        <h2>{{ t('policy.rules') }}</h2>
        <ButtonSuccess v-if="isUpdatedPersonalDataRules" :label="t('button.changesSaved')" class="btn-fix-height"/>
        <Button v-else @click="updatePersonalDataRules" :loading="isUpdatePersonalDataRulesLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
      </div>
      <MainCard :title="t('websiteCard.infrastructure.input')">
        <Editor v-model="personalDataRulesFields"></Editor>
        <span v-if="updatePersonalDataRulesError?.data.text" class="flex text-xs color-error">
        {{ updatePersonalDataRulesError?.data.text[0] }}
      </span>
      </MainCard>
    </section>
    <Skeleton v-else height="256px"></Skeleton>
  </div>
</template>
<style lang="scss" scoped>
</style>