import http from "@/plugins/axios";

const privacyPolicyApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/agreement/privacy-policy', { signal: abortController?.signal })
    return data
}

const termsOfUseApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/agreement/terms-of-use', { signal: abortController?.signal })
    return data
}

const personalDataRulesApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/agreement/personal-data-rules', { signal: abortController?.signal })
    return data
}

const updatePrivacyPolicyApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/agreement/privacy-policy',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const updateTermsOfUseApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/agreement/terms-of-use',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const updatePersonalDataRulesApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/agreement/personal-data-rules',
        { ...params },
        { signal: abortController?.signal })
    return data
}

export {
    privacyPolicyApiCall,
    termsOfUseApiCall,
    personalDataRulesApiCall,
    updatePrivacyPolicyApiCall,
    updateTermsOfUseApiCall,
    updatePersonalDataRulesApiCall,
}