<script>
import {defineComponent, onMounted, ref, unref, watch, computed} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {useTariff} from "@/hooks/tariff";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import Breadcrumb from "@/components/Breadcrumb.vue";
import MainCard from "@/components/cards/MainCard.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {Button, Breadcrumb, MainCard, InputText, Dropdown, Editor, ConfirmationModal, InputNumber, ButtonSuccess},
  setup() {
    const route = useRoute();
    const router = useRouter()
    const store = useStore();
    const {form, tariff, isUpdated, updateTariff, destroyTariff, loadTariff, v$} = useTariff();
    const {t} = useI18n();

    form.value = {...unref(tariff)};

    const visibleConfirmationModal = computed(() => store.getters.getConfirmationDestroyTariffModal);

    const breadcrumbs = ref([]);

    const changeConfirmationStateModal = () => {
      store.dispatch('changeStateModal', {
        type: 'confirmationDestroyTariffModal',
        bool: !visibleConfirmationModal.value
      });
    };

    const toggleUpdateTariff = async () => {
      try {
        await updateTariff(tariff.value.id, form.value);
      } catch (e) {
        console.error(e);
      }
    };

    const toggleDestroyTariff = async () => {
      try {
        await changeConfirmationStateModal();
        await destroyTariff(route.params.id);
        await router.push({name: 'tariffs'});
      } catch (e) {
        console.error(e);
      }
    };

    watch(tariff, () => {
      form.value = {...unref(tariff)};
      breadcrumbs.value = [
        {label: t('menu.tariffs'), router: {name: 'tariffs'}},
        {label: form.value.name_ru}
      ];
      isUpdated.value = false
    })

    onMounted(async () => {
      await loadTariff(route.params.id)
      breadcrumbs.value = [
        {label: t('menu.tariffs'), router: {name: 'tariffs'}},
        {label: form.value.name_ru}
      ];
    });

    return {
      breadcrumbs,
      form,
      visibleConfirmationModal,
      changeConfirmationStateModal,
      toggleUpdateTariff,
      toggleDestroyTariff,
      isUpdated,
      t,
      v$,
      tariff,
    };
  }
});
</script>

<template>
  <template v-if="form.name_ru">
    <ConfirmationModal v-model:visible="visibleConfirmationModal">
      <template #header>
        {{ t('modals.tariff-destroy.header') }}
      </template>

      <template #default>
        <span>{{ t('modals.tariff-destroy.text') }}</span>
      </template>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="t('labels.cancel')" @click="changeConfirmationStateModal"
                  class="btn-primary-outlined font-light w-12"/>
          <Button :label="t('labels.destroy')" @click="toggleDestroyTariff" class="btn-primary font-light ml-3 w-12"/>
        </div>
      </template>
    </ConfirmationModal>

    <section class="py-2 mb-3">
      <div class="flex justify-content-between">
        <Breadcrumb :data="breadcrumbs" separator="/"/>
        <div class="flex">
          <ButtonSuccess v-if="isUpdated" :label="t('labels.changes-saved')" />
          <Button :if="!isUpdated" :label="t('labels.save-changes')" @click="toggleUpdateTariff" class="btn-primary font-light ml-2"/>
        </div>
      </div>
    </section>

    <section class="py-2 mb-3">
      <div class="grid h-max">
        <div class="col-12 md:col-4">
          <MainCard :styles="{'h-full': true}" :title="t('card-names.tariff-name')">
            <div class="grid">
              <div class="col-12">
             <span class="p-float-label w-full">
              <InputText
                  disabled
                  v-model="form.name_ru"
                  id="name"
                  class="w-full"
                  :class="{'p-invalid': v$.name_ru.$errors.length}"
              />
              <label for="name">{{ t('card-names.name') }} *</label>
             </span>
                <span v-if="v$.name_ru.$errors.length" class="color-error text-xs">
                {{ t(v$.name_ru.$errors[0].$message) }}
              </span>
              </div>
            </div>
          </MainCard>
        </div>
      </div>
    </section>

    <section class="py-2 mb-3">
      <div class="grid">
        <div class="col-12">
          <MainCard :title="t('card-names.tariff-cost-description')">
            <div class="grid">
              <div class="col-12">
                <Editor v-model="form.description_ru" class="w-full"/>
                <span v-if="v$.description_ru.$errors.length" class="color-error text-xs">
                {{ t(v$.description_ru.$errors[0].$message) }}
              </span>
              </div>
            </div>
          </MainCard>
        </div>
      </div>
    </section>

    <section  class="py-2 mb-4">
      <div class="flex justify-content-end">
      <span class="color-error underline cursor-pointer" @click="changeConfirmationStateModal">
        {{ t('modals.tariff-destroy.header') }}
      </span>
      </div>
    </section>
  </template>
</template>